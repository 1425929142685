import { Button } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import { BASE_URL } from "../../api/setup";
import CosButton from "../../components/CosButton";
import FiltroMenuClientes from "../../components/filtros/filter-menu-clientes";
import MyModalModal, { ModalStateProps } from "../../components/modal";
import { EntrevistadoTipo } from "../../constants/cliente-form";
import { useBetterState } from "../../hooks/use-better-state";
import tasks from "../../utils/tasks";
import ClienteRelatorioViewModel, {
  NavFilter,
} from "../../viewmodel/relatorios/clientes";

const ClientListaAdmin = () => {
  const {
    viewLog,
    clienteState,
    currentView,
    clientes,
    setClientes,
    loadPage,
    loadClientes,
    dropdownNavState,
    email,
    listenerNav,
    handleClick,
    handleClienteState,
  } = ClienteRelatorioViewModel();

  const [showDownloadButton, setShowDownloadButton] = useState(true);

  const deleteData = useBetterState<any>({
    isLoading: false,
    data: 0,
  });
  const modalState = useBetterState<ModalStateProps>({
    show: false,
  });

  const handleDeletingData = (id: number) => {
    deleteData.value = {
      isLoading: true,
      data: id,
    };
    api.delete(
      {
        path: "/api/cliente",
        params: {
          id: Number(id),
        },
      },
      (data) => {
        deleteData.value = {
          isLoading: false,
          data: id,
        };
        const newClient = clientes.data.filter((item: any) => {
          return item.id !== id;
        });
        setClientes({
          ...clientes,
          data: newClient,
        });
      },
      (errors) => {
        deleteData.value = {
          isLoading: false,
          data: id,
        };
      },
    );
  };

  const links = [
    `${BASE_URL}/api/cliente/download`,
    `${BASE_URL}/api/cliente/download/area-situacao`,
    `${BASE_URL}/api/cliente/download/bairro-situacao`,
    `${BASE_URL}/api/cliente/download/b-clientes`,
    `${BASE_URL}/api/cliente/download/filter-clientes`,
    `${BASE_URL}/api/cliente/download/registo-suspeita`,
  ];

  useEffect(() => {
    updateLinkExcel(currentView.value);
  }, [dropdownNavState]);

  const link = useBetterState(links[0]);

  const updateLinkExcel = (type: NavFilter) => {
    setShowDownloadButton(true);
    const regiao = dropdownNavState.value.regiao;
    const situacao = dropdownNavState.value.situacao;

    if (type === "TODOS") {
      link.value = links[0];
    } else if (type === "AREAO") {
      const regiaoParam = regiao === "" ? "" : `area=${regiao}`;
      const situacaoParam =
        situacao === "" ? "" : `situacao=${situacao.replace(" Situações", "")}`;
      let sufix = "";

      if (regiaoParam !== "" && situacaoParam !== "") {
        sufix = `${regiaoParam}&${situacaoParam}`;
      } else if (regiaoParam !== "") {
        sufix = `${regiaoParam}`;
      } else if (situacaoParam !== "") {
        sufix = `${situacaoParam}`;
      } else {
        return;
      }

      link.value = `${links[1]}?${sufix}`;
      console.log(link.value);
    } else if (type === "BAIRRO") {
      const regiaoParam = regiao === "" ? "" : `bairro=${regiao}`;
      const situacaoParam =
        situacao === "" ? "" : `situacao=${situacao.replace(" Situações", "")}`;
      let sufix = "";

      if (regiaoParam !== "" && situacaoParam !== "") {
        sufix = `${regiaoParam}&${situacaoParam}`;
      } else if (regiaoParam !== "") {
        sufix = `${regiaoParam}`;
      } else if (situacaoParam !== "") {
        sufix = `${situacaoParam}`;
      } else {
        return;
      }
      link.value = `${links[2]}?${sufix}`;
    } else if (type === "BRIGADISTAS") {
      link.value = `${`${BASE_URL}/api/cliente/download/b-clientes`}?email=${email}`;
    } else if (type === "REGISTO_SUSPEITA") {
      const regiaoParam = regiao === "" ? "" : `registo=${regiao}`;
      const situacaoParam = situacao === "" ? "" : `suspeita=${situacao}`;
      let sufix = "";

      if (regiaoParam !== "" && situacaoParam !== "") {
        sufix = `${regiaoParam}&${situacaoParam}`;
      } else if (regiaoParam !== "") {
        sufix = `${regiaoParam}`;
      } else if (situacaoParam !== "") {
        sufix = `${situacaoParam}`;
      } else {
        return;
      }
      link.value = `${links[5]}?${sufix}`;
    } else {
      setShowDownloadButton(false);
    }
  };

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      <MyModalModal
        state={modalState?.value}
        handleDismiss={() => {
          modalState.value = {
            show: false,
          };
        }}
        handleNo={() => {
          modalState.value = {
            show: false,
          };
        }}
        handleYes={(data: any) => {
          modalState.value = {
            show: false,
          };
          handleDeletingData(data);
        }}
      />
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px]">
        <div className="flex flex-1 px-[40px] pt-[20px] flex-col">
          <div className="flex flex-1 justify-between">
            <span className=" text-[20px] font-bold">Lista de Clientes</span>
            {showDownloadButton && (
              <Link to={link.value}>
                <CosButton>BAIXAR EXCEL</CosButton>
              </Link>
            )}
          </div>
          <div>
            <FiltroMenuClientes
              valorPesquisa={clienteState.value.value}
              handleChangeNc={handleClienteState}
              listener={(state, dropdownNav, email) => {
                updateLinkExcel(state);
                listenerNav(state, dropdownNav, email);
              }}
              onClick={handleClick}
            />
          </div>
          <div className="w-full flex flex-1">
            {viewLog.show && (
              <div className="border-[1px] border-blue-800 w-full flex flex-row justify-center items-center mx-[5px] mb-[5px] rounded-[5px]">
                <p className="text-center">{viewLog.message}</p>
              </div>
            )}
          </div>
        </div>
        <div className="w-full px-[20px] overflow-auto mb-[30px] tablet:px-[30px]">
          <Paper className="flex-1" sx={{ width: "100%" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome Completo</TableCell>
                    <TableCell align="right">contacto 1</TableCell>
                    <TableCell align="right">Bairro</TableCell>
                    <TableCell align="right">Data Registro</TableCell>
                    <TableCell align="right">Acções</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientes.data.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.nome}
                      </TableCell>
                      <TableCell align="right">
                        {tasks.verificarValor(row.contacto1, "Sem informação")}
                      </TableCell>
                      <TableCell align="right">
                        {tasks.verificarValor(row.bairro, "Sem informação")}
                      </TableCell>
                      <TableCell align="right">
                        {row.data_registro
                          ? tasks.convertToGMTPlus2(row.data_registro)
                          : tasks.verificarValor(
                              row.data_registro,
                              "Sem informação",
                            )}
                      </TableCell>
                      <TableCell align="right">
                        {
                          <div className="flex flex-row justify-end gap-[15px]">
                            <Link
                              to={
                                clientes.data.find((item: any) => {
                                  return item.id === row.id;
                                })?.tipo_registo == EntrevistadoTipo.CLIENTE
                                  ? `/geral/nav/info-cliente/${row.id}`
                                  : `/geral/nav/info-potencial/${row.id}`
                              }
                            >
                              <Button variant="contained">Detalhes</Button>
                            </Link>
                            <Button
                              onClick={() => {
                                modalState.value = {
                                  data: row.id,
                                  show: true,
                                  message: `Tem certeza que pretende remover o usuário ${
                                    clientes.data.find((item: any) => {
                                      return item.id === row.id;
                                    })?.nome
                                  }.`,
                                } as ModalStateProps;
                              }}
                              variant="contained"
                              style={{ backgroundColor: "#ed1c38" }}
                            >
                              Delete
                            </Button>
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div className="mt-[20px] flex flex-1 justify-end">
            <Pagination
              count={clientes.pagination.totalPages}
              color="primary"
              onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                loadPage(page);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientListaAdmin;
