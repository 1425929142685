import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import * as React from "react";
import { useState } from "react";
import { EntrevistadoTipo } from "../../constants/cliente-form";
import { Constants } from "../../constants/home-constants";
import {
  DropdownNavState,
  NavFilter,
} from "../../viewmodel/relatorios/mapa-geral";
import useResourcesZus from "../../zustand/resources-zus";
import CosButton from "../CosButton";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface FiltroMenuGeralProps {
  listener: (
    state: NavFilter,
    dropdownNav: DropdownNavState,
    email: string,
  ) => void;
  onClick?: () => void;
  handleChangeNc?: (type: string, key: string) => void;
  handleClickSearch?: () => void;
  valorPesquisa?: string;
  view?: "REL" | "MAPA";
}

export default function FiltroMenuClientes({
  listener,
  onClick,
  handleChangeNc,
  handleClickSearch,
  view = "REL",
  valorPesquisa,
}: FiltroMenuGeralProps) {
  const pontosDePesquisa = [
    "Conta Contrato",
    "Número de Instalação",
    "Número do Contador",
    "Parceiro de Negócios",
    "Numero de Telefone",
  ];
  const [pesquisa, setPesquisa] = useState(pontosDePesquisa[0]);
  const { bairros, areas } = useResourcesZus();
  const dateB = new Date();
  const [value, setValue] = React.useState(0);

  const [briEmail, setBriEmail] = useState("");
  const [data_registro, setDataRegistro] = useState(
    `${dateB.getFullYear()}-${dateB.getMonth() + 1}-${dateB.getDate()}`,
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [bairro, setBairro] = useState("");
  const [situacao, setSituacao] = useState("");
  const [area, setArea] = useState("");
  const [registo, setRegisto] = useState("");
  const [suspeita, setSuspeita] = useState("");

  React.useEffect(() => {
    if (value === 0) {
      listener(
        "TODOS",
        {
          regiao: "",
          situacao: "",
        },
        "",
      );
    } else if (value === 1) {
      setArea("");
      setBairro("");
      setSituacao("");
      listener(
        "AREAO",
        {
          regiao: "",
          situacao: "",
        },
        "",
      );
    } else if (value === 2) {
      setArea("");
      setBairro("");
      setSituacao("");
      listener(
        "BAIRRO",
        {
          regiao: "",
          situacao: "",
        },
        "",
      );
    } else if (value === 3) {
      listener(
        "BRIGADISTAS",
        {
          regiao: "",
          situacao: `${dateB.getFullYear()}-${dateB.getMonth() + 1}-${dateB.getDate()}`,
        },
        "",
      );
    } else if (value == 4) {
      listener(
        "REGISTO_SUSPEITA",
        {
          regiao: "",
          situacao: "",
        },
        "",
      );
    } else {
      listener(
        "CLIENTE",
        {
          regiao: "",
          situacao: "none",
        },
        "",
      );
    }
  }, [value]);

  React.useEffect(() => {
    if (value === 1) {
      listener(
        "AREAO",
        {
          regiao: area,
          situacao: situacao,
        },
        "",
      );
    } else if (value === 2) {
      listener(
        "BAIRRO",
        {
          regiao: bairro,
          situacao: situacao,
        },
        "",
      );
    } else if (value === 3) {
      listener(
        "BRIGADISTAS",
        {
          regiao: briEmail,
          situacao: data_registro,
        },
        "",
      );
    } else if (value === 4) {
      listener(
        "REGISTO_SUSPEITA",
        {
          regiao: registo,
          situacao: suspeita,
        },
        "",
      );
    }
  }, [bairro, situacao, area, registo, suspeita, data_registro, briEmail]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Todos" {...a11yProps(0)} />
          <Tab label="Area Operacional" {...a11yProps(1)} />
          <Tab label="Bairro" {...a11yProps(2)} />
          <Tab label="Brigadista" {...a11yProps(3)} />
          <Tab label="Tipo Registo/Suspeita" {...a11yProps(4)} />
          {view === "REL" && <Tab label="Cliente" {...a11yProps(5)} />}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}></CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className="flex flex-col gap-[10px] px-[15px] md:flex-row md:gap-[20px] md:items-center">
          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-label">
              Área Operacional
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={area}
              label="Área Operacional"
              onChange={(event) => {
                setArea(event.target.value);
              }}
            >
              {areas.map((item) => {
                return (
                  <MenuItem value={item.area_operacional}>
                    {item.area_operacional}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel id="bairro_drop">Situação</InputLabel>
            <Select
              labelId="bairro_drop"
              value={situacao}
              label="Situação"
              onChange={(event) => {
                setSituacao(event.target.value);
              }}
            >
              <MenuItem value={Constants.situacaoCadastro.correcto}>
                {Constants.situacaoCadastro.correcto}
              </MenuItem>
              <MenuItem value={Constants.situacaoCadastro.incompleto}>
                {Constants.situacaoCadastro.incompleto}
              </MenuItem>
              <MenuItem value={Constants.situacaoCadastro.irregular}>
                {Constants.situacaoCadastro.irregular}
              </MenuItem>
              <MenuItem value={Constants.situacaoCadastro.todas}>
                {Constants.situacaoCadastro.todas}
              </MenuItem>
            </Select>
          </FormControl>
          <div>
            <Button
              style={{ paddingLeft: 15, paddingRight: 15 }}
              className="w-full md:w-auto"
              variant="contained"
              onClick={onClick}
            >
              PROCURAR
            </Button>
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <div className="flex flex-col gap-[10px] px-[15px] md:flex-row md:gap-[20px] md:items-center">
          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel id="bairro_drop">Bairro</InputLabel>
            <Select
              labelId="bairro_drop"
              value={bairro}
              label="Bairro"
              onChange={(event) => {
                setBairro(event.target.value);
              }}
            >
              {bairros.map((item) => {
                return <MenuItem value={item.bairro}>{item.bairro}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel id="bairro_drop">Situação</InputLabel>
            <Select
              labelId="bairro_drop"
              value={situacao}
              label="Situação"
              onChange={(event) => {
                setSituacao(event.target.value);
              }}
            >
              <MenuItem value={Constants.situacaoCadastro.correcto}>
                {Constants.situacaoCadastro.correcto}
              </MenuItem>
              <MenuItem value={Constants.situacaoCadastro.incompleto}>
                {Constants.situacaoCadastro.incompleto}
              </MenuItem>
              <MenuItem value={Constants.situacaoCadastro.irregular}>
                {Constants.situacaoCadastro.irregular}
              </MenuItem>
              <MenuItem value={Constants.situacaoCadastro.todas}>
                {Constants.situacaoCadastro.todas}
              </MenuItem>
            </Select>
          </FormControl>
          <div>
            <Button
              style={{ paddingLeft: 15, paddingRight: 15 }}
              className="w-full md:w-auto"
              variant="contained"
              onClick={onClick}
            >
              PROCURAR
            </Button>
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <div className="flex flex-col gap-[10px] px-[15px] md:flex-row md:gap-[20px] md:items-center">
          <TextField
            size="small"
            placeholder="Email"
            fullWidth
            value={briEmail}
            onChange={(event) => {
              setBriEmail(event.target.value);
            }}
          />
          <div className="flex flex-1 flex-col">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateField", "DateField"]}>
                <DateField
                  fullWidth
                  size="small"
                  label="Data do Registo"
                  value={dayjs(data_registro)}
                  format="DD-MM-YYYY"
                  onChange={(event) => {
                    setDataRegistro(event?.format("YYYY-MM-DD") as any);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div>
            <Button
              style={{ paddingLeft: 15, paddingRight: 15 }}
              className="w-full md:w-auto"
              variant="contained"
              onClick={onClick}
            >
              PROCURAR
            </Button>
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <div className="flex flex-col gap-[10px] px-[15px] md:flex-row md:gap-[20px] md:items-center">
          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-la">Tipo Entrevisto</InputLabel>
            <Select
              labelId="demo-simple-select-la"
              id="demo-simple-select"
              value={registo}
              label="Tipo de Entrevistado"
              onChange={(event) => {
                setRegisto(event.target.value);
              }}
            >
              <MenuItem value={EntrevistadoTipo.CLIENTE}>
                {EntrevistadoTipo.CLIENTE}
              </MenuItem>
              <MenuItem value={EntrevistadoTipo.POTENCIAL}>
                {EntrevistadoTipo.POTENCIAL}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel id="bairr_dro">Situação Suspeita</InputLabel>
            <Select
              labelId="bairr_drp"
              value={suspeita}
              label="Situação Suspeita"
              onChange={(event) => {
                setSuspeita(event.target.value);
              }}
            >
              <MenuItem value={"Sim"}>{`Sim`}</MenuItem>
              <MenuItem value={"Não"}>{`Não`}</MenuItem>
            </Select>
          </FormControl>
          <div>
            <Button
              style={{ paddingLeft: 15, paddingRight: 15 }}
              className="w-full md:w-auto"
              variant="contained"
              onClick={onClick}
            >
              PROCURAR
            </Button>
          </div>
        </div>
      </CustomTabPanel>
      {view === "REL" && (
        <CustomTabPanel value={value} index={5}>
          <div className=" w-full flex flex-1 flex-col gap-[15px] tablet:flex-row tablet: justify-center">
            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel id="bairro_drop">Tipo de pesquisa</InputLabel>
              <Select
                labelId="bairro_drop"
                value={pesquisa}
                label="Tipo de pesquisa"
                onChange={(event) => {
                  setPesquisa(event.target.value);
                  console.log(event.target.value);
                  if (event.target.value === "Conta Contrato") {
                    handleChangeNc?.("CC", "");
                  } else if (event.target.value === "Número de Instalação") {
                    handleChangeNc?.("NI", "");
                  } else if (event.target.value === "Número do Contador") {
                    handleChangeNc?.("NC", "");
                  } else if (event.target.value === "Numero de Telefone") {
                    handleChangeNc?.("CT", "");
                  } else {
                    handleChangeNc?.("PN", "");
                  }
                }}
              >
                {pontosDePesquisa.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <div className="w-full">
              <TextField
                size="small"
                fullWidth
                placeholder={pesquisa}
                value={valorPesquisa}
                label={pesquisa}
                onChange={(event) => {
                  if (pesquisa === "Conta Contrato") {
                    handleChangeNc?.("CC", event.target.value);
                  } else if (pesquisa === "Número de Instalação") {
                    handleChangeNc?.("NI", event.target.value);
                  } else if (pesquisa === "Número do Contador") {
                    handleChangeNc?.("NC", event.target.value);
                  } else if (pesquisa === "Numero de Telefone") {
                    handleChangeNc?.("CT", event.target.value);
                  } else {
                    handleChangeNc?.("PN", event.target.value);
                  }
                }}
              />
            </div>
            <CosButton
              style={{ minWidth: 135 }}
              // isLoading={isLoadingSearch}
              size="small"
              variant="contained"
              onClick={onClick}
            >
              PROCURAR
            </CosButton>
          </div>
        </CustomTabPanel>
      )}
    </Box>
  );
}
