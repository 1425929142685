import Pagination from "@mui/material/Pagination";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import FiltroMenuClientes from "../../components/filtros/filter-menu-clientes";
import { ModalStateProps } from "../../components/modal";
import { EntrevistadoTipo } from "../../constants/cliente-form";
import { Constants } from "../../constants/home-constants";
import { useBetterState } from "../../hooks/use-better-state";
import MapaGeralViewModel from "../../viewmodel/relatorios/mapa-geral";

const MapaGeral = () => {
  const {
    viewLog,
    clienteState,
    currentView,
    clientes,
    setClientes,
    pageq,
    loadPage,
    loadClientes,
    listenerNav,
    handleClick,
    handleClienteState,
  } = MapaGeralViewModel();

  const deleteData = useBetterState<any>({
    isLoading: false,
    data: 0,
  });
  const modalState = useBetterState<ModalStateProps>({
    show: false,
  });

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px]">
        <div className="flex flex-1 px-[40px] pt-[20px] flex-col">
          <div className="flex flex-1 flex-row justify-between flex-wrap items-center">
            <div className="flex flex-1">
              <span className=" text-[20px] font-bold">Lista de Clientes</span>
            </div>
          </div>
          <div>
            <FiltroMenuClientes
              valorPesquisa={clienteState.value.value}
              handleChangeNc={handleClienteState}
              listener={(state, dropdownNav, email) => {
                // updateLinkExcel(state);
                listenerNav(state, dropdownNav, email);
              }}
              onClick={handleClick}
            />
          </div>
          <div className="w-full flex flex-1">
            {viewLog.show && (
              <div className="border-[1px] border-blue-800 w-full flex flex-row justify-center items-center mx-[5px] mb-[5px] rounded-[5px]">
                <p className="text-center">{viewLog.message}</p>
              </div>
            )}
          </div>
        </div>
        <div className="w-full px-[20px] overflow-auto mb-[30px] tablet:px-[30px]">
          <div className="w-full h-[500px]">
            <APIProvider
              apiKey={"AIzaSyB5v8QIe5AcXYGeGwZuGUcnZh_21ZyBHxM"}
              onLoad={() => {
                console.log("Mapa carregado !!!");
              }}
            >
              <Map
                defaultZoom={8}
                defaultCenter={{ lat: -25.9566848, lng: 32.5790043 }}
              >
                {clientes.data.map((item, index) => {
                  return (
                    <Marker
                      title={`${item.nome} - cnt: ${item.contacto1}`}
                      position={{
                        lat: Number(item.latitude),
                        lng: Number(item.longitude),
                      }}
                      icon={getPinColor(
                        item.tipo_registo,
                        item.situacao_entrevistado,
                        item.suspeita_ilegal,
                      )}
                    />
                  );
                })}
              </Map>
            </APIProvider>
          </div>
          <div className="mt-[20px] flex flex-1 justify-end">
            <Pagination
              count={clientes.pagination.totalPages}
              color="primary"
              onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                loadPage(page);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const getPinColor = (tipo: string, situacao: string, suspeita: string) => {
  if (tipo === EntrevistadoTipo.POTENCIAL && suspeita === "Sim") {
    return "http://maps.google.com/mapfiles/ms/icons/red.png";
  } else if (tipo === EntrevistadoTipo.POTENCIAL && suspeita === "Não") {
    return "http://maps.google.com/mapfiles/ms/icons/purple.png";
  }

  if (situacao === Constants.situacaoCadastro.correcto) {
    return "http://maps.google.com/mapfiles/ms/icons/green.png";
  } else if (situacao === Constants.situacaoCadastro.irregular) {
    return "http://maps.google.com/mapfiles/ms/icons/red.png";
  } else if (situacao === Constants.situacaoCadastro.incompleto) {
    return "http://maps.google.com/mapfiles/ms/icons/yellow.png";
  }
};

export default MapaGeral;
